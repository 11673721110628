import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar"
import About from "./About"
import BlogLanding from './Blog';
import { BlogSelect } from './Blog';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css'

const App = () => (
    <Router>
        <div >
            <Navbar />
            <Routes>
                <Route path='/research/' element={<BlogLanding />} />
                <Route path='/research/:title' element={<BlogSelect />} />
                <Route path='/' element={<About />} />
            </Routes>
        </div>
    </Router>
);

export default App;