import React from 'react';
import BlogList from './BlogList'
import { useParams } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Footer from "./components/Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock, faFire, faUser } from '@fortawesome/free-solid-svg-icons'

// Format of each blog post
const BlogPostSelect = ({ title, summary, body, publishedDate, readingTime, backgroundPic, subTitle }) =>
(
  <div className="blog-post">
    <h1 align="center" style={{ overflow: "hidden", paddingBottom: "5%", fontSize: "5vw", paddingTop: "5%", color: "black" }}><span style={{ width: "100%", borderBottom: "10px solid #ccc" }}>{title}</span></h1>
    <hr />
    <div align="center" className='post-info'>
      <p> <FontAwesomeIcon icon={faFire} /> {subTitle}</p>
      <p> <FontAwesomeIcon icon={faUser} /> Author: Sam Wallace</p>
      <p> <FontAwesomeIcon icon={faCalendar} /> Published: {publishedDate}</p>
      <p> <FontAwesomeIcon icon={faClock} /> Reading time: {readingTime}</p>
    </div>
    <hr />
    <img
      src={backgroundPic} style={{ padding: "8%", width: "100%" }}>
    </img>
    <h2 style={{}}> Summary</h2>
    <p>{summary}</p>
    {body}
  </div >
);

export function BlogPost({ title, subTitle, summary, link, backgroundPic }) {
  return (
    <>
      <div className='col-sm-4' style={{ paddingBottom: "15%" }}>

        <Card style={{ height: "100%", boxShadow: "0 5px 15px rgba(0,0,0,.08)" }}>
          < a href={link}><Card.Img style={{ padding: "5%", maxHeight: "200px", width: "100%", objectFit: "scale-down" }} src={backgroundPic} /></a>
          <Card.Text style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: "10%" }}>
            <h4 className="exp-card-title">{title}</h4>
            <h4 className='exp-sub-title'>{subTitle} </h4>
            <p className="exp-card-body1">{summary}</p>
            <a style={{ color: "black" }} href={link}>Read More</a>
          </Card.Text>
        </Card>
      </div>
    </>
  );
}

// Blog tab
export const BlogSelect = () => {

  let { title } = useParams();
  var post = ""
  for (var i = 0; i < BlogList.length; ++i) {

    if (BlogList[i].title == title) {
      post = BlogList[i]
      break
    }
  }

  return (
    <div>
      <div className='blog-app-select'>
        <BlogPostSelect
          key={post.title}
          title={post.title}
          summary={post.summary}
          publishedDate={post.publishedDate}
          readingTime={post.readingTime}
          link={post.linkShare}
          body={post.body}
          subTitle={post.subTitle}
          backgroundPic={post.backgroundPic}
        />
      </div>
      <Footer></Footer>
    </div>
  );
};

// Blog Landing
const BlogLanding = () => {
  return (
    <div >
      <div className='bloglanding' style={{ paddingLeft: "15%", paddingRight: "15%", paddingBottom: "15%" }}>
        <h1 align="center" style={{ overflow: "hidden", paddingBottom: "5%", fontSize: "5vw", paddingTop: "5%", }}><span style={{ width: "100%", borderBottom: "10px solid #ccc" }}>Research</span></h1>
        <div className='row' align="center" style={{ justifyContent: "center" }}>
          {
            BlogList.map(post => (

              <BlogPost
                key={post.title}
                title={post.title}
                summary={post.summary}
                publishedDate={post.publishedDate}
                readingTime={post.readingTime}
                link={post.linkShare}
                backgroundPic={post.backgroundPic}
                subTitle={post.subTitle}
              />
            ))
          }
        </div>
      </div>
      <Footer></Footer>
    </div>

  )
};

export default BlogLanding;
