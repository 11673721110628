import React from 'react';

const Logos = () => {
    return (

        <div style={{ top: "65%", right: "5%", position: "absolute" }}>
            <ul style={{ display: "flex", justifyContent: 'right', listStyle: 'none', paddingRight: "10%" }}>
                <li><a href="https://www.linkedin.com/in/infosecsamwallace" target="_blank" rel="noreferrer">
                    <img style={{ width: "40px", height: "auto" }} src="/content/linkedin_black.png" alt="LinkedIn" loading="lazy" /> </a></li>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <li><a href="https://github.com/thecasual" target="_blank" rel="noreferrer"> <img style={{ maxHeight: "40px", width: "auto" }} src="https://cdn.svgporn.com/logos/github-icon.svg" alt="GitHub" loading="lazy" /> </a></li>
            </ul>
        </div>
    );
}

export default Logos