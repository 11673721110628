import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Navvy = () => {
  return (
    <Navbar style={{ width: "100%" }} collapseOnSelect bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/"><img style={{ height: "2rem", width: "auto" }} src="/content/sam-wallace-logo.png"></img></Navbar.Brand>
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link eventKey={2} href="/research">
            Research
          </Nav.Link>
          <Nav.Link href="https://www.buymeacoffee.com/samwallace" target="_blank" rel="noreferrer">
            <img src="https://cdn.buymeacoffee.com/buttons/v2/default-white.png"

              alt="Buy Me A Coffee"
              style={{ height: "2rem", width: "auto", maxWidth: "100%", objectFit: "scale-down" }}

            >
            </img>
          </Nav.Link>
        </Nav>

      </Container>
    </Navbar >
  );
}

export default Navvy;