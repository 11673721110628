import React from "react";
import Certifications from "./Certifications";
import Experience from "./Experience";
import Logos from "./Logos";
import Footer from "./components/Footer"

// About Tab
const About = () => {
    return (

        <React.Fragment>
            <div style={{}}>
                <div style={{ minHeight: "187px", position: "relative" }}>
                    <img style={{ maxWidth: "100%", height: "auto" }} src="/content/Iceland_edited.jpg"></img>
                    <Logos />
                </div>
                <h1 align="center" style={{ overflow: "hidden", paddingBottom: "5%", fontSize: "5vw", paddingTop: "5%", }}><span style={{ width: "100%", borderBottom: "10px solid #ccc" }}>  Experience</span></h1>
                <Experience />
                <h1 align="center" style={{ fontSize: "5vw", paddingTop: "8%", paddingBottom: "5%" }}> <span style={{ width: "100%", borderBottom: "10px solid #ccc" }}>Certifications</span></h1>
                <Certifications />
            </div>
            <Footer></Footer>
        </React.Fragment >
    );
};

export default About;