import React from "react";
import Card from 'react-bootstrap/Card';

export function ExperienceObj({ title, backgroundPic, company, employmentDates }) {
    return (
        <>
            <div className='col-sm-3' style={{ paddingBottom: "5%" }}>
                <Card style={{ overflow: "visible", boxShadow: "0 5px 15px rgba(0,0,0,.08)", boxSizing: "border-box", margin: "1%", height: "100%" }}>
                    <Card.Body style={{ backgroundColor: "white", }}>
                        <Card.Img className="exp-img" src={backgroundPic} />
                    </Card.Body>
                    <Card.Text style={{ paddingLeft: "5%", paddingRight: "5%", paddingBottom: "10%" }}>
                        <h4 className="exp-card-title">{title}</h4>
                        <p className="exp-card-body1">{company}</p>
                        <p className="exp-card-body2">{employmentDates}</p>
                    </Card.Text>
                </Card>
            </div>
        </>
    );
}

const ExperienceList = [
    {
        title: "Staff Application Security Engineer",
        company: 'Redacted',
        employmentDates: 'May 2022 - Current',
        backgroundPic: "/content/redacted.jpg",
    },
    {
        title: 'Senior Penetration Tester',
        company: "University of Kansas Health System",
        employmentDates: "Apr 2021 - May 2022",
        backgroundPic: "/content/ukhs.png",
    },
    {
        title: 'Senior Information Security Analyst',
        company: "TouchNet Information Systems",
        employmentDates: "Dec 2017 - Apr 2021",
        backgroundPic: "/content/tn.png",
    }
    ,
    {
        title: 'Cyber Warfare Operations Specialist',
        company: "United States Air Force",
        employmentDates: 'Aug 2012 - Dec 2017',
        backgroundPic: "/content/af.jpg",
    }
];

const Experience = () => {
    return (

        <div className='exp' align="center" style={{ paddingLeft: "12%", paddingRight: "12%" }}>
            <div className='row' >
                {
                    ExperienceList.map(post => (
                        <ExperienceObj
                            title={post.title}
                            backgroundPic={post.backgroundPic}
                            company={post.company}
                            employmentDates={post.employmentDates}
                        />
                    ))
                }
            </div>
        </div>
    )
};

export default Experience