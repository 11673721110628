import Navbar from 'react-bootstrap/Navbar';

const Footer = () => {
  return (
    <>

      <Navbar style={{ width: "100%", height: "22em", justifyContent: "center", paddingBottom: "1%", paddingTop: "1%" }} collapseOnSelect bg="dark" variant="dark">
        <div>
          <img style={{ height: "7rem", width: "auto" }} src="/content/sam-wallace-logo-long.png"></img>
          <p style={{ color: "white", textAlign: "center", paddingTop: "13%" }}> <img style={{ height: "1.2em", width: "1.2em" }} alt="Creative Commons" src="https://mirrors.creativecommons.org/presskit/icons/cc.svg"></img> <img style={{ height: "1.2em", width: "1.2em" }} alt="Creative Commons" src="https://mirrors.creativecommons.org/presskit/icons/by.svg"></img> 2023 Sam Wallace</p>
          <p style={{ textAlign: "center" }}><a style={{ color: "white" }} href="https://www.linkedin.com/in/infosecsamwallace" target="_blank" rel="noreferrer">LinkedIn</a></p>
          <p style={{ textAlign: "center" }}><a style={{ color: "white" }} href="https://github.com/thecasual" target="_blank" rel="noreferrer">GitHub</a></p>
        </div>
      </Navbar >
    </>
  )
}

export default Footer;