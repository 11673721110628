import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownPage = ({ filePath }) => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        const fetchMarkdown = async () => {
            const response = await fetch(filePath);
            const markdown = await response.text();
            setMarkdown(markdown);
        };

        fetchMarkdown();
    }, []);

    return (
        <ReactMarkdown
            children={markdown}
            components={
                {
                    img: ({ node, ...props }) => <img style={{ maxWidth: '100%', padding: "5%" }}{...props} />
                }}
        />

    );
};

export default MarkdownPage;
