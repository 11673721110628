import React from "react";
import Card from 'react-bootstrap/Card';

export function CertObj({ link, src }) {
    return (
        <>
            <div className='col' align="center" style={{ maxWidth: "100%" }}>
                <Card style={{ border: "none" }}>
                    <Card.Body >
                        <a href={link} target="_blank" rel="noreferrer">
                            <Card.Img style={{ backgroundColor: "white", width: "7rem" }} src={src} />
                        </a>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export const CertList = [
    { link: "https://www.youracclaim.com/badges/61d31b0b-04e7-4c84-bc81-46d89aa5d2c6", src: "https://images.credly.com/size/340x340/images/0a375324-4f9e-412d-b276-b6e96c428709/image.png" },
    { link: "https://verified.elearnsecurity.com/certificates/cf2f1e34-8014-4aaa-95a1-c84b57091c0a", src: "/content/ewptx.jpg" },
    { link: "https://aspen.eccouncil.org/VerifyBadge?type=certification&a=vqN9Ama2zIM7IkEab8O/6RALOt++LXfROjsKyU1fMcg=", src: "/content/eccouncil.png" },
    { link: "https://www.credly.com/badges/3b933562-ad08-40bc-b206-4ff8e8580231", src: "https://images.credly.com/size/340x340/images/74790a75-8451-400a-8536-92d792c5184a/CompTIA_Security_2Bce.png" },
    { link: "https://www.youracclaim.com/badges/54c0dea2-148f-440b-b261-83eaa961c85e", src: "https://images.credly.com/size/340x340/images/d8acded5-3919-42fc-bae3-b9157cb427e8/image.png" },
    { link: "https://aspen.eccouncil.org/VerifyBadge?type=certification&a=KxRPxuyDhc10cSjaKiXdlSLMg5/JdCRMOqX0vuvzQmg=", src: "/content/ceh.png" },
    { link: "https://www.youracclaim.com/badges/a4e65297-b22d-4326-87f4-1039fdd0117f", src: "https://images.credly.com/size/340x340/images/061f34d8-aa10-44d6-90d2-99ae0b864214/image.png" },
];

const Certifications = () => {
    return (

        <div className='container' align="center" style={{ paddingBottom: "15%" }}>
            <div className='row' align="center">
                {CertList.map(post => (
                    <CertObj
                        src={post.src}
                        link={post.link}
                    />
                ))}
            </div>
        </div>
    )
};

export default Certifications