import React from "react";
import MarkdownPage from './MarkdownPage';
// List of blog posts
const BlogList = [
    {
        title: 'Stored XSS in GMS 1.0',
        subTitle: 'CVE-2022-41358',
        summary: `Garage Management System utilizes client side validation to prevent XSS.
        Using burp, a request can be modified and replayed to the server bypassing this validation which creates an avenue for XSS.`,
        body: <MarkdownPage filePath={"/content/post1.md"} />,
        publishedDate: "Dec 12, 2022",
        readingTime: "5 minutes",
        linkShare: "/research/Stored XSS in GMS 1.0",
        backgroundPic: "/content/gms.jpg"

    },
    {
        title: 'Harvesting Emails with Expired Garmin LiveTrack Sessions',
        subTitle: 'CVE-2022-46081',
        summary: `In Garmin Connect 4.61, terminating a LiveTrack session wouldn't prevent the LiveTrack API from continued exposure of private personal information.`,
        body: <MarkdownPage filePath={"/content/post2.md"} />,
        publishedDate: "Dec 31, 2022",
        readingTime: "10 minutes",
        linkShare: "/research/Harvesting Emails with Expired Garmin LiveTrack Sessions",
        backgroundPic: "/content/2/stop-livetrack.png"

    },
    {
        title: 'DOM XSS: Skimming Credit Cards',
        subTitle: 'XSS',
        summary: `Skimming credit cards by exploiting a DOM XSS vulnerability in a react application. This is for educational purposes only.`,
        body: <MarkdownPage filePath={"/content/post3.md"} />,
        publishedDate: "March 3, 2023",
        readingTime: "10 minutes",
        linkShare: "/research/DOM XSS: Skimming Credit Cards",
        backgroundPic: "/content/3/cc_collection.png"
    },
    {
        title: 'Can AI empowered open source tools compete with their premium counterparts?',
        subTitle: 'AI',
        summary: `Guest blog post on the Dispareo Security blog!`,
        body: <MarkdownPage filePath={"/content/post4.md"} />,
        publishedDate: "March 28, 2023",
        readingTime: "6 minutes",
        linkShare: "https://blog.dispareosecurity.com/can-ai-empowered-open-source-tools-compete-with-their-premium-counterparts-guest-blog-9683c3df71e5",
        backgroundPic: "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*6DOzlTPmtg64fbbhC0FNXg.png"
    }
];


export default BlogList